import logoImg from '../assets/images/logo.svg';

const AppFooter = (props) => (
    <footer className="row">
        <div className="col-12 col-lg-9 col-xl-8 my-5 px-3 px-sm-5 mx-auto">
            <small>{props.text.paragraph}</small>
            <div className="row text-center align-items-center">
                <div className="col-12 col-md-4">
                    <img src={logoImg} alt="onom-nom" />
                </div>
                <div className="col-12 col-md-4">
                    <p className="text-center m-0"><small>© {new Date().getFullYear()} Onom-nom.</small></p>
                </div>
            </div>
        </div>
    </footer>
);

export default AppFooter