import React from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AppHeader from "./AppHeader";
import Email from "./Email";

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals("ScrollTrigger", ScrollTrigger);
}

const importAll = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item));
    });
    return images;
  },
  images = importAll(
    require.context("./../assets/images/main", true, /\.(svg)$/)
  );

class AppMain extends React.Component {
  showElement(elem, direction) {
    if (elem.style.opacity === "1") return;

    direction = direction | 1;

    let x = 0,
      y = 0,
      delay = 0;

    elem.classList.forEach((d) => {
      if (d.includes("delay-")) delay = d.replace("delay-", "");
    });

    if (elem.classList.contains("slide-right")) {
      x = -100;
      y = 0;
    } else if (elem.classList.contains("slide-left")) {
      x = 100;
      y = 0;
    } else if (elem.classList.contains("slide-up")) {
      x = 0;
      y = direction * 100;
    } else if (elem.classList.contains("slide-down")) {
      x = 0;
      y = -direction * 100;
    }

    gsap.fromTo(
      elem,
      { x: x, y: y, autoAlpha: 0 },
      {
        duration: 1.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
        delay: delay,
      }
    );
  }

  hideElement(elem) {
    gsap.set(elem, { autoAlpha: 0 });
  }
  componentDidMount() {
    const { showElement, hideElement } = this;

    gsap.utils.toArray(".animate").forEach(function (elem) {
      hideElement(elem);

      ScrollTrigger.create({
        trigger: elem,
        onEnter: function () {
          showElement(elem);
        },
      });
    });
  }
  render() {
    const { text } = this.props;

    return (
      <main>
        <AppHeader text={text.header} />
        <div className="row row--light-bg text-center" id="howItWorks">
          <div className="col-12 col-lg-9 col-xl-8 my-5 px-3 px-sm-5 mx-auto">
            {text.how_it_works.header}
            <div className="row">
              {text.how_it_works.sections.map((d, i) => (
                <div key={i} className="col-6 col-md-3 px-4">
                  <img
                    src={images[`how-it-works_0${i + 1}.svg`]}
                    className={`animate slide-up delay-${i * 0.2}`}
                  />
                  <p className="my-3">{d.paragraph}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row" id="features">
          <div className="col-12 col-lg-9 col-xl-8 my-5 px-3 px-sm-5 mx-auto">
            {text.features.header}
            {text.features.sections.map((d, i) => (
              <div key={i} className="row align-items-center">
                <div className="col-12 col-sm-6 px-3 px-sm-5">
                  <img
                    src={images[`features_0${i + 1}.svg`]}
                    className={`animate ${
                      !(i % 2) ? "slide-right" : "slide-left"
                    } delay-.75`}
                    alt={`feature icon ${i}`}
                  />
                </div>
                <div className="col-12 col-sm-6 pl-3 pr-5 animate delay-.75">
                  {d.header}
                  {d.paragraph}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="row row--light-bg" id="emailForm">
          <div className="col-12 col-lg-9 col-xl-8 my-5 px-3 px-sm-5 mx-auto">
            <Email text={text.email_form} />
          </div>
        </div>
      </main>
    );
  }
}

export default AppMain;
