import React from "react";

const languages = {
  en: {
    nav: {
      labels: {
        howItWorks: <>How it works</>,
        features: <>Features</>,
        emailForm: <>Sign up</>,
      },
    },
    home: {
      header: {
        header: (
          <>
            <h1>
              <strong>Lose weight</strong>
            </h1>
            <h1>without the tears</h1>
          </>
        ),
        paragraph: (
          <p>
            <strong>
              Reach your weight and health goals eating the foods you love
            </strong>{" "}
            while keeping track of your progress with our visual and easy to use
            tools.
          </p>
        ),
        cta: <>SIGN UP</>,
      },
      how_it_works: {
        header: <h2>How it works?</h2>,
        sections: [
          {
            paragraph: <>Choose a goal to generate your custom eating plan</>,
          },
          {
            paragraph: <>Keep track of the foods you eat everyday</>,
          },
          {
            paragraph: <>Check-in daily and/or weekly</>,
          },
          {
            paragraph: <>Your plan gets updated until you reach your goal</>,
          },
        ],
      },
      features: {
        header: <h2>Features</h2>,
        sections: [
          {
            header: (
              <h3>
                Simple and down-to-earth <br />
                serving sizes
              </h3>
            ),
            paragraph: (
              <p>
                We get it, nutrient labels and serving sizes are sometimes hard
                to understand. That's why our food database has sensible and
                easy to use serving information so food tracking is a breeze.
              </p>
            ),
          },
          {
            header: <h3>Visual nutrition system</h3>,
            paragraph: (
              <p>
                100g of carbs? 300 calories? What does that even mean? We get
                it, nutrition sometimes can be difficult, but it doesn’t need to
                be! That’s why our app uses our state-of-the-art visual
                nutrition system to help you succeed!
              </p>
            ),
          },
          {
            header: <h3>No idea what or how much to eat?</h3>,
            paragraph: (
              <p>
                Piece of cake, our super intelligent algorithm will find
                delicious foods for you to try each day*.
              </p>
            ),
          },
          {
            header: (
              <h3>
                Barcode scanner? <br />
                Yeah we got that too!
              </h3>
            ),
            paragraph: (
              <p>
                Grab your food package, find the barcode, scan it. That’s it!*
              </p>
            ),
          },
        ],
      },
      email_form: {
        header: <h2 className="email--header">And much more!</h2>,
        paragraph: (
          <p>
            Sign up now to get notified when the app gets live and get an{" "}
            <strong>exclusive welcome bonus surprise!</strong>
          </p>
        ),
        labels: {
          first_name: <>First Name</>,
          last_name: <>Last Name</>,
          email: <>Email</>,
          submit: <>Sign Up</>,
        },
        messages: {
          default: "",
          sending: "Signing up...",
          success: "Success! You've been signed up to our mailing list.",
          error: "Something went wrong, please try again later.",
          duplicate:
            "It looks like you've already been subscribed from this email address.",
        },
      },
    },
    footer: {
      paragraph: (
        <>
          <p>*Feature available in Q4 2024</p>
          <p>
            Onom-nom is not a physician or registered dietitian. The contents of
            this website and app should not be taken as medical advice. It is
            not intended to diagnose, treat, cure, or prevent any health problem
            - nor is it intended to replace the advice of a physician. Always
            consult your physician or qualified health professional on any
            matters regarding your health.
          </p>
        </>
      ),
    },
  },
  es: {
    nav: {
      labels: {
        howItWorks: <>¿Cómo funciona?</>,
        features: <>Características</>,
        emailForm: <>Registrarse</>,
      },
    },
    home: {
      header: {
        header: (
          <>
            <h1>
              <strong>Controla tu peso</strong>
            </h1>
            <h1>sin sufrir</h1>
          </>
        ),
        paragraph: (
          <p>
            <strong>
              ¿Deseas perder grasa, ganar músculo, o mantener un peso saludable?
            </strong>{" "}
            Alcanza tu peso soñado comiendo los alimentos que más te gusten, con
            herramientas fáciles de usar.
          </p>
        ),
        cta: <>REGISTRARSE</>,
      },
      how_it_works: {
        header: <h2>¿Cómo funciona?</h2>,
        sections: [
          {
            paragraph: (
              <>Escoge una meta y obtén un plan de alimentación personalizado</>
            ),
          },
          {
            paragraph: <>Lleva un diario de comidas</>,
          },
          {
            paragraph: <>Registra tu peso de forma semanal o diaria</>,
          },
          {
            paragraph: <>Tu plan se actualiza conforme te acerques a tu meta</>,
          },
        ],
      },
      features: {
        header: <h2>Características</h2>,
        sections: [
          {
            header: (
              <h3>
                Porciones de comidas <br />
                simples de usar
              </h3>
            ),
            paragraph: (
              <p>
                Muchas veces las porciones en las etiquetas de los alimentos son
                difíciles de entender. Nuestra base de datos cuenta con
                porciones caseras y amigables.
              </p>
            ),
          },
          {
            header: <h3>Prueba nuestro sistema visual de nutrición</h3>,
            paragraph: (
              <p>
                ¿100 gramos de carbohidratos? ¿100 calorías? Lo entendemos, a
                nosotros también nos dio mareos. Con nuestro sistema todo eso
                será parte del pasado.
              </p>
            ),
          },
          {
            header: <h3>¿Sin idea de qué o cuánto comer?</h3>,
            paragraph: (
              <p>
                No hay problema, nuestro super inteligente algoritmo buscará
                comidas y recetas deliciosas por tí*.
              </p>
            ),
          },
          {
            header: (
              <h3>
                ¿No te gusta tipear? <br />
                ¡Usa códigos de barras!
              </h3>
            ),
            paragraph: (
              <p>
                Coge cualquier empaque, busca su código de barras, escanéalo y
                listo. Podrás usarlo en cualquiera de tus comidas del día*.
              </p>
            ),
          },
        ],
      },
      email_form: {
        header: <h2 className="email--header">¡Y mucho más!</h2>,
        paragraph: (
          <p>
            Regístrate para ser uno de los primeros en nuestra aplicación y{" "}
            <strong>recibe un bonus especial de bienvenida!</strong>
          </p>
        ),
        labels: {
          first_name: <>Nombre</>,
          last_name: <>Apellido</>,
          email: <>Email</>,
          submit: <>Registrarse</>,
        },
        messages: {
          default: "",
          sending: "Enviando ...",
          success:
            "¡Listo! ¡Te mandaremos un mensaje con tu bonus cuando nuestra app se publique!",
          error: "¡Oops! Algo pasó, inténtalo renuevo.",
          duplicate: "¡Oops! Al parecer ya estás inscrito.",
        },
      },
    },
    footer: {
      paragraph: (
        <>
          <p>*Características disponibles en el Q4 del 2024</p>
          <p>
            Onom-nom no reemplaza a un doctor o nutricionista. El contenido de
            esta página web y aplicación no debe tomarse en cuenta como una
            evaluación médica. No está diseñada para diagnosticar, tratar, curar
            o prevenir ningún problema de salud o enfermedad. Siempre consulta a
            tu médico o nutricionista antes de empezar cualquier plan que
            involucre tu salud.
          </p>
        </>
      ),
    },
  },
};

export default languages;
