import { Button } from "react-bootstrap";
import headerImg from "../assets/images/header.svg";

const AppHeader = (props) => (
  <header className="row">
    <div className="col-12 col-lg-9 col-xl-8 mt-5 pt-5 px-3 px-sm-5 mx-auto">
      <div className="row flex-sm-row-reverse">
        <div className="col-12 col-sm-9 col-md-7 px-4 pb-5 mx-auto">
          <div className="my-3">{props.text.header}</div>
          <div className="my-3">{props.text.paragraph}</div>
          <a href="#emailForm">
            <Button className="px-3 px-sm-5 animate">{props.text.cta}</Button>
          </a>
        </div>
        <div className="col-12 col-md-5 align-self-md-end px-4">
          <img src={headerImg} className="animate slide-up" alt="hand" />
        </div>
      </div>
    </div>
  </header>
);

export default AppHeader;
