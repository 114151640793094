import React from 'react';
import AppNav from './components/AppNav';
import AppMain from './components/AppMain';
import AppFooter from './components/AppFooter';
import { StateContext } from './context/context';
import languages from './assets/lang/languages';
import ReactGA from 'react-ga';
import ttiPolyfill from 'tti-polyfill';

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);


const App = () => {
  const { language } = React.useContext(StateContext)
  React.useEffect(() => {

    ReactGA.pageview(window.location.pathname)

    ttiPolyfill.getFirstConsistentlyInteractive().then((tti) => {
      ReactGA.timing({
        category: "Load Performace",
        variable: 'Time to Interactive',
        value: tti
      })
    });


    const callback = list => {
      list.getEntries().forEach(entry => {
        console.log(entry)
        ReactGA.timing({
          category: "First Meaningful Paint",
          variable: entry.name,
          value: entry.responseEnd
        })
      })
    }

    var observer = new PerformanceObserver(callback);
    observer.observe({
      entryTypes: [
        'navigation',
        'paint',
        'resource',
        'mark',
        'measure',
        'frame',
        'longtask'
      ]
    })

  })

  return (
    <div className="container-fluid">
      <AppNav text={languages[language].nav} />
      <AppMain text={languages[language].home} />
      <AppFooter text={languages[language].footer} />
    </div>
  );
}

export default App;
