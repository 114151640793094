import React from 'react';
import { languageReducer } from './reducers';
import { StateContext, DispatchContext } from './context';

function Provider({ children }) {
    const available_langs = ["en", "es"],
        client_lang = navigator.language.split('-')[0],
        [language, onSetLanguage] = React.useReducer(languageReducer, { language: available_langs.includes(client_lang) ? client_lang : "es" });

    return (
        <StateContext.Provider value={language}>
            <DispatchContext.Provider value={onSetLanguage}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}

export default Provider;