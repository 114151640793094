import React from 'react';
import jsonp from 'jsonp';
import queryString from 'query-string';
import { Form, Button, Collapse } from 'react-bootstrap';
import emailImg from '../assets/images/email.svg';

class Email extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      EMAIL: "",
      FIRST_NAME: "",
      LAST_NAME: "",
      MESSAGE: this.props.text.messages.default,
      is_open: false
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();

    const url = `${process.env.REACT_APP_MAILER_URL}?u=${process.env.REACT_APP_USER}&id=${process.env.REACT_APP_ID}`,
      query = queryString.stringify(this.state);

    let message = "";

    jsonp(`${url}&${query}`, { param: 'c' }, (err, d) => {
      if (err) message = this.props.text.messages.error;
      else {
        console.log(query, d)
        if (d.result === "success") message = this.props.text.messages.success
        else if (d.msg.includes("too many") || d.msg.includes("already subscribed")) message = this.props.text.messages.duplicate
        else message = this.props.text.messages.error
      }

      this.setState({
        MESSAGE: message,
        is_open: true
      })
    });
  }
  componentDidMount() {
  

    /* ReactGA.pageview(window.location.pathname + "#email") */
  }
  render() {
    const { text } = this.props,
      { EMAIL, FIRST_NAME, LAST_NAME, MESSAGE, is_open } = this.state;

    return (
      <div>
        <div className="col-12 col-md-8 col-lg-7 col-xl-6 py-2 mx-auto text-center">
          <img src={emailImg} className="animate slide-up delay-.75" alt="Email" />
          {text.header}
          {text.paragraph}
          <Form onSubmit={this.handleSubmit}>

            <Form.Group>
              <Form.Label className="text-left d-block">{text.labels.first_name}</Form.Label>
              <Form.Control
                required
                type="text"
                name="FIRST_NAME"
                id="FIRST_NAME"
                value={FIRST_NAME}
                onChange={(e) => { this.setState({ FIRST_NAME: e.target.value }); }} />
            </Form.Group>

            <Form.Group>
              <Form.Label className="text-left d-block">{text.labels.last_name}</Form.Label>
              <Form.Control
                required
                type="text"
                name="LAST_NAME"
                id="LAST_NAME"
                value={LAST_NAME}
                onChange={(e) => { this.setState({ LAST_NAME: e.target.value }); }} />
            </Form.Group>

            <Form.Group className="mb-5">
              <Form.Label className="text-left d-block">{text.labels.email}</Form.Label>
              <Form.Control
                required
                type="email"
                name="EMAIL"
                id="EMAIL"
                value={EMAIL}
                onChange={(e) => { this.setState({ EMAIL: e.target.value }); }} />
            </Form.Group>

            {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden='true' aria-label="Please leave the following three fields empty">
              <label htmlFor="b_first_name">{text.labels.first_name}</label>
              <input type="text" name="b_first_name" tabIndex="-1" value="" placeholder="first name" id="b_first_name" />

              <label htmlFor="b_last_name">{text.labels.last_name}</label>
              <input type="text" name="b_last_name" tabIndex="-1" value="" placeholder="last name" id="b_last_name" />

              <label htmlFor="b_email">{text.labels.email}</label>
              <input type="email" name="b_email" tabIndex="-1" value="" placeholder="email" id="b_email" />
            </div>

            <Button type="submit" value="Subscribe" name="subscribe" className="d-block w-100 animate delay-1">{text.labels.submit}</Button>
            <Collapse in={is_open}>
              <div id="emailMessage">{MESSAGE}</div>
            </Collapse>
          </Form>
        </div>
      </div>
    )
  };
}

export default Email