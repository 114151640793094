import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { StateContext, DispatchContext } from '../context/context';
import logoImg from '../assets/images/logo.svg';

const AppNav = (props) => {
    const { language } = React.useContext(StateContext),
        languageDispatch = React.useContext(DispatchContext);
       
    return (
        <nav className="row fixed-top">
            <div className="col-12 col-lg-9 col-xl-8 my-3 px-3 px-sm-5 mx-auto d-flex align-items-stretch justify-content-between">
                <Navbar expand="lg" as="div">
                    <Navbar.Brand className="nav-icon">
                        <a href="#">
                            <img src={logoImg} alt="onom-nom" />
                        </a>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="nav-menu" />
                    <Navbar.Collapse id="nav-menu" className="nav-menu">
                        <Nav>
                            <Nav.Item variant="secondary">
                                <Nav.Link href="#howItWorks">{props.text.labels.howItWorks}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="#features">{props.text.labels.features}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="#emailForm">{props.text.labels.emailForm}</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <Nav variant="pills" className="nav-lang my-2">
                    <Nav.Item defaultActiveKey="es">
                        <Nav.Link active={language === "en"} eventKey="en" onClick={(e) => languageDispatch(e["currentTarget"]["attributes"]["data-rb-event-key"]["value"])}>EN</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link active={language === "es"} eventKey="es" onClick={(e) => languageDispatch(e["currentTarget"]["attributes"]["data-rb-event-key"]["value"])}>ES</Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
        </nav>
    )
};

export default AppNav